import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import EmailCaptureModal from '../components/email-capture-modal'
import EpgPlayer from '../components/epgPlayer'
import Footer from '../components/footer'
import Header from '../components/header'
import SEO from '../components/seo'
import { setGaScreenName } from '../utils'

const LazyLoadedEPG = React.lazy(() => import('../components/EPG/components/TVGuide'))

const IndexPage = ({ location }) => {
    const [channel, setChannel] = useState({})
    const [mobilePoster, setMobilePoster] = useState('')

    const isSSR = typeof window === 'undefined'

    useEffect(() => {
        setGaScreenName('Channel Guide')
    }, [])

    return (
        <IndexWrapper>
            <SEO title="Channel Guide" />
            <Header currentPath={location.pathname} />
            <EmailCaptureModal />
            {!isSSR ? (
                <React.Suspense fallback={<div className="epg-loading-filler" />}>
                    <ScheduleWrapper>
                        <div id="epg-mobile-poster">
                            <img src={mobilePoster} alt="poster for currently playing program" />
                            <div className="gradient" />
                        </div>
                        <EpgPlayer channel={channel} shouldHideControls shouldAutoplay />
                        <LazyLoadedEPG handleChangeChannel={setChannel} setMobilePoster={setMobilePoster} />
                    </ScheduleWrapper>
                </React.Suspense>
            ) : (
                <div className="epg-loading-filler" />
            )}
            <Footer />
        </IndexWrapper>
    )
}

const IndexWrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;

    .epg-loading-filler {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
    }

    @media screen and (min-width: 672px) {
        #epg-player-wrapper #player-back-btn {
            display: none !important;
        }
    }
`

const ScheduleWrapper = styled.div`
    display: flex;
    flex-flow: column;
    position: relative;
    flex: 1 1 auto;

    #epg-mobile-poster {
        display: none;
    }

    @media screen and (max-width: 672px) {
        #epg-mobile-poster {
            position: absolute;
            top: 0;
            display: flex;
            width: 100vw;

            img {
                width: 100%;
                height: 100%;
            }

            div.gradient {
                position: absolute;
                width: 100%;
                height: 90px;
                background: linear-gradient(-180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.95) 100%);
                border-radius: 0px;
                bottom: 0;
            }
        }

        #epg-player-wrapper > div.video-js {
            display: none;
        }
    }
`

export default IndexPage
